import React, { FC, memo, useState } from 'react';
import { OrderContext } from '../../../context/OrderContext';
import './ReviewOrder.scss';
import { chooseLaterString } from '../Forms/Partials/Product/ProductFormConstants';
import { Button, Confirm } from 'semantic-ui-react';
import { FeatureType, OrderStatus } from 'models';
import { OrderType, ServerOrder } from 'models/Order';
import {
  ApplyToAllTread,
  ChooseLaterString,
  SameAsBottom,
  SameAsFloor,
  TreadValue,
  YES,
  getFeatureType,
  isFeatureRemovable,
} from '../Forms/Partials/Features/Feature/FeatureConstants';
import { features } from 'process';
import { BaseDeckFormType } from '../Forms/Partials/Features/Feature/FeatureSchema';

interface ReviewOrderProps {
  deleteOrder: (order?: ServerOrder) => void;
  cancelQuote: () => void;
}

/**
 * Renders field groups for each key from orderSchema
 * @param props
 * @constructor
 */
export const ReviewOrder = (props: ReviewOrderProps) => {
  const { state } = React.useContext(OrderContext);
  const swatch = state.swatches.find(
    (swatch) =>
      swatch.color === state.product.color &&
      swatch.pattern === state.product.fabric
  );
  const isAfterQuote =
    state.order?.status === OrderStatus.QuotedOptions ||
    state.order?.status === OrderStatus.ReadyForOrder;
  const isAfterOrder = state.order?.status === OrderStatus.Ordered;
  const type = state.order?.type ?? state.project.type;
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const hasValueForShipping =
    state.shipping.whereToShip ||
    state.shipping.name ||
    state.shipping.addressLine1 ||
    state.shipping.addressLine2 ||
    state.shipping.city ||
    state.shipping.zip ||
    state.shipping.shippingState;

  const noteControl = (note: string) => {
    const list: JSX.Element[] = [];
    if (note && note != 'null') {
      const noteList = note.split('. ');
      for (let index = 0; index < noteList.length; index++) {
        if (noteList[index].length !== 0) {
          list.push(
            <>
              <div>
                <ReviewField
                  value={noteList[index].length == 0 ? 'null' : noteList[index]}
                  label={index == 0 ? 'Field Notes' : 'Feature Notes'}
                />{' '}
              </div>
            </>
          );
        }
      }
    }
    return list;
  };

  const getTreadComment = (comment: string, feature: BaseDeckFormType) => {
    const controls = [];
    if (comment && comment.length > 0) {
      if (comment.includes(ApplyToAllTread)) {
        const index = comment.indexOf(ApplyToAllTread);
        if (comment.length > index) {
          const value = comment.substring(0, index);
          if ((feature?.measurement?.step_treads?.numTreads ?? 0) > 0) {
            for (
              let index = 0;
              index < (feature?.measurement?.step_treads?.numTreads ?? 0);
              index++
            ) {
              const title = TreadValue + (index + 1).toString();
              controls.push({
                key: title,
                value: value,
              });
            }
          }
        }
      } else {
        const treadInfoList = comment.split('\n');
        for (let index = 0; index < treadInfoList.length; index++) {
          const treadInfo = treadInfoList[index].split(':');
          if (treadInfo.length == 2) {
            const value = treadInfo[1].toString();
            const title = TreadValue + (index + 1).toString();
            controls.push({
              key: title,
              value: value,
            });
          }
        }
      }
    }

    return controls;
  };
  return (
    <div className="review-order">
      <div className="review-order__fields review-order__fields--product">
        <div className="review-order__sub-title">Product</div>
        <ReviewField
          value={state.product.newOrReplacement}
          label={`Replacement ${type === OrderType.Cover ? 'Cover' : 'liner'}`}
        />
        {state.product.partner && (
          <ReviewField value={state.product.partner} label="Purchase Partner" />
        )}
        {state.product.brand && (
          <ReviewField value={state.product.brand.name} label="Brand" />
        )}
        {type === OrderType.Cover && (
          <>
            {state.product.gridSpacing && (
              <ReviewField
                value={state.product.gridSpacing}
                label="Grid Spacing"
              />
            )}
            {state.product.material && (
              <ReviewField value={state.product.material} label="Material" />
            )}
            {state.product.material !== chooseLaterString && (
              <ReviewField value={state.product.fabric} label="Fabric" />
            )}
            {state.product.color && (
              <div className="review-order__field">
                <div className="review-order__field__label">Color</div>
                <img
                  className="review-order__field__img"
                  src={swatch?.url}
                  alt={state.product.color}
                />
                <div className="review-order__field__value">
                  {swatch?.color}
                </div>
              </div>
            )}
            {state.product.drainOrPump && (
              <ReviewField
                value={state.product.drainOrPump}
                label="Drain or pump"
              />
            )}
            {state.product.pumpQty && (
              <ReviewField
                value={state.product.pumpQty}
                label="Pump Quantity"
              />
            )}
            {state.product.commercialSprings && (
              <ReviewField
                value={state.product.commercialSprings}
                label="Commercial springs"
              />
            )}
          </>
        )}
        {type === OrderType.Liner && (isAfterQuote || isAfterOrder) && (
          <>
            {state.product.material && (
              <ReviewField value={state.product.material} label="Material" />
            )}
            {state.product.millGauge && (
              <ReviewField
                value={state.product.millGauge}
                label="Material Thickness"
              ></ReviewField>
            )}

            {state.product.bottomMillGauge && (
              <ReviewField
                value={state.product.bottomMillGauge}
                label="Bottom Material Thickness"
              ></ReviewField>
            )}
            {state.product.linerPattern && (
              <ReviewField
                value={state.product.linerPattern}
                label="Liner Pattern"
              />
            )}
            {state.product.linerBottomPattern && (
              <ReviewField
                value={state.product.linerBottomPattern}
                label="Liner Bottom Pattern"
              />
            )}
            {state.product.bead && (
              <ReviewField value={state.product.bead} label="Bead" />
            )}
          </>
        )}
        {type === OrderType.Liner && (
          <>
            <ReviewField value={state.product.overlap} label="Overlap" />
            {state.product.overlapSize && (
              <ReviewField
                value={state.product.overlapSize}
                label="Overlap Comments"
              />
            )}
          </>
        )}
      </div>

      <div className="review-order__fields review-order__fields--features">
        <div className="review-order__sub-title">Features</div>
        {state?.features?.features?.length ? (
          state?.features?.features?.map((feature, index) => (
            <span key={index}>
              <div className="review-order__features--title">
                {feature.featureName}
              </div>
              {feature?.notes && feature.notes.length > 0 ? (
                noteControl(feature.notes)
              ) : (
                <div></div>
              )}
              {type === OrderType.Liner &&
                (feature.featureType === FeatureType.VinylFeature ||
                  getFeatureType(feature.featureLabel ?? '') ===
                    FeatureType.VinylFeature) && (
                  <>
                    <ReviewField
                      value={
                        SameAsFloor.toLocaleLowerCase().includes(
                          feature?.stepMaterialOption?.toLocaleLowerCase() ??
                            ChooseLaterString
                        )
                          ? SameAsFloor
                          : feature.stepMaterialOption ?? undefined
                      }
                      label="Step Material Option"
                    />
                    {feature.stepMaterial &&
                      (isAfterQuote || isAfterOrder) &&
                      feature.stepMaterial.length > 0 && (
                        <ReviewField
                          value={feature.linerStepMaterial?.label}
                          label="Step Material"
                        />
                      )}
                    <ReviewField
                      value={feature.stepFastener ?? undefined}
                      label="Step Fastener Type"
                    />
                    {feature.stepComment && feature.stepComment.length > 0 && (
                      <TreadListControl
                        value={getTreadComment(feature.stepComment, feature)}
                      />
                    )}

                    <ReviewField
                      value={feature.stepStripe ?? undefined}
                      label="White stripe on each tread"
                    />
                    {feature.stepStripe === YES && (
                      <ReviewField
                        value={feature.stripeWidth ?? undefined}
                        label="Stripe Width"
                      />
                    )}
                  </>
                )}
              {type === OrderType.Cover && (
                <>
                  {feature.anchorTypeOptions && (
                    <ReviewField
                      value={feature.anchorTypeOptions ?? undefined}
                      label="Anchor Type"
                    />
                  )}
                  {feature.cableAssembly != undefined && (
                    <ReviewField
                      value={feature.cableAssembly ?? undefined}
                      label="Cable Assembly"
                    />
                  )}
                  {(isFeatureRemovable(feature.featureType) ||
                    feature.featureType === FeatureType.Other) && (
                    <ReviewField
                      value={feature.isRemovable ?? undefined}
                      label="Is Removable?"
                    />
                  )}
                  {feature.featureType === FeatureType.Spa && (
                    <ReviewField
                      value={feature.isCovered ?? undefined}
                      label="Is Covered?"
                    />
                  )}
                </>
              )}
            </span>
          ))
        ) : (
          <div>No features were tagged during measuring.</div>
        )}
      </div>

      {type === OrderType.Cover && (
        <div className="review-order__fields review-order__fields--anchors">
          <div className="review-order__sub-title">Anchors</div>
          {state?.anchors.decks &&
            state.anchors.decks.map((anchor, index) => (
              <span key={index}>
                <div className="review-order__features--title">
                  {state.decksData[index]?.label ?? 'Decking'}
                </div>
                {anchor.decking && (
                  <ReviewField value={anchor.decking} label="Decking Type" />
                )}
                {anchor.hardware && (
                  <ReviewField value={anchor.hardware} label="Hardware" />
                )}
                {anchor.collars && (
                  <ReviewField value={anchor.collars} label="Collars" />
                )}
                {anchor.padding && (
                  <ReviewField value={anchor.padding} label="Padding" />
                )}
                {anchor.paddingType && (
                  <ReviewField
                    value={anchor.paddingType}
                    label="Padding Type"
                  />
                )}
              </span>
            ))}
        </div>
      )}

      <div className="review-order__fields review-order__fields--accessories">
        <div className="review-order__sub-title">Accessories</div>
        {type === OrderType.Cover && (
          <>
            <ReviewField
              value={state.accessories.doubleD_Rings}
              label="Double D-Rings"
            />
            <ReviewField
              value={state.accessories.topAndBottomWebbing}
              label="Top and Bottom Webbing"
            />
            <ReviewField value={state.accessories.buckles} label="Buckles" />
            <ReviewField value={state.accessories.yStraps} label="Y Strap" />
            {state.accessories.yStrapQty && (
              <ReviewField
                value={state.accessories.yStrapQty}
                label="Y Strap Qty"
              />
            )}
          </>
        )}
        {type === OrderType.Liner && (
          <>
            <ReviewField
              value={state.accessories.divingTarget}
              label="Diving Target"
            />
            {state.accessories.divingTargetComments && (
              <ReviewField
                value={state.accessories.divingTargetComments}
                label="Diving Target Comments"
              />
            )}
            <ReviewField
              value={state.accessories.racingStripes}
              label="Racing Stripes"
            />
            {state.accessories.racingStripesComments && (
              <ReviewField
                value={state.accessories.racingStripesComments}
                label="Racing Stripes Comments"
              />
            )}
            {state.accessories.racingStripesCount && (
              <ReviewField
                value={state.accessories.racingStripesCount}
                label="Racing Stripes Count"
              />
            )}
            <ReviewField
              value={state.accessories.safetyStripes}
              label="Safety Stripes"
            />
            {state.accessories.safetyStripes &&
              state.accessories.safetyStripesColor && (
                <ReviewField
                  value={state.accessories.safetyStripesColor}
                  label="Safety Stripes Color"
                />
              )}
            {state.accessories.safetyStripes &&
              state.accessories.safetyStripeWidth && (
                <ReviewField
                  value={state.accessories.safetyStripeWidth}
                  label="Safety Stripe Width"
                />
              )}
            <ReviewField
              value={state.accessories.solidShallowEnd}
              label="Solid Shallow End"
            />
            {state.accessories.solidShallowEndColor && (
              <ReviewField
                value={state.accessories.solidShallowEndColor}
                label="Solid Shallow End Color"
              />
            )}
          </>
        )}
      </div>
      <div className="review-order__fields review-order__fields--shipping">
        {hasValueForShipping && (
          <div className="review-order__sub-title">Shipping</div>
        )}
        {state.shipping.whereToShip && (
          <ReviewField
            value={state.shipping.whereToShip}
            label="Shipping to:"
          />
        )}
        {state.shipping.name && (
          <ReviewField value={state.shipping.name} label="Name" />
        )}
        {state.shipping.addressLine1 && (
          <ReviewField value={state.shipping.addressLine1} label="Address" />
        )}
        {state.shipping.addressLine2 && (
          <ReviewField
            value={state.shipping.addressLine2}
            label="Address (cont.)"
          />
        )}
        {state.shipping.city && (
          <ReviewField value={state.shipping.city} label="City" />
        )}
        {state.shipping.shippingState && (
          <ReviewField value={state.shipping.shippingState} label="State" />
        )}
        {state.shipping.zip && (
          <ReviewField value={state.shipping.zip} label="ZIP" />
        )}
      </div>
      {(state.order?.status === OrderStatus.Saved || isAfterQuote) && (
        <div className="review-order__delete">
          <Button
            basic
            color="red"
            icon="trash"
            content={isAfterQuote ? 'Delete Order' : 'Cancel Quote'}
            onClick={() => setIsConfirmModalOpen(true)}
          />
          <Confirm
            size="mini"
            open={isConfirmModalOpen}
            header={`About to ${
              isAfterQuote ? 'Delete Order' : 'Cancel Quote'
            }`}
            content={'Are you sure?'}
            onConfirm={() => props.deleteOrder(state.order)}
            confirmButton={isAfterQuote ? 'Delete' : 'Cancel Quote'}
            onCancel={() => setIsConfirmModalOpen(false)}
          />
        </div>
      )}
    </div>
  );
};

export const ReviewField = (props: {
  label: string;
  value: boolean | string | undefined;
}) => {
  return (
    <div className="review-order__field">
      <div className="review-order__field__label">{props.label}</div>
      {typeof props.value === 'boolean' && (
        <div className="review-order__field__value">
          {props.value ? 'Yes' : 'No'}
        </div>
      )}
      {typeof props.value !== 'boolean' && (
        <div className="review-order__field__value">{props?.value}</div>
      )}
    </div>
  );
};

const TreadItem: FC<{ item: { key: string; value: string } }> = memo(
  (props) => {
    const { item } = props;
    return (
      <div>
        <ReviewField label={item.key} value={item.value} />
      </div>
    );
  }
);
TreadItem.displayName = 'TreadItem';

export const TreadListControl = (props: {
  value: { key: string; value: string }[];
}) => {
  return (
    <div>
      {props.value.map((data) => (
        <TreadItem key={data.key} item={data}></TreadItem>
      ))}
    </div>
  );
};
